<template>
  <div id="costId">
    <div class="page-container pb-0 mb-2">
      <div id="filter-custom">
        <h3
          class="header"
        >
          Bộ lọc
        </h3>
        <b-row>
          <b-col
            md="4"
            xl="4"
          >
            <b-form-group>
              <label
                for="basicInput"
              >Năm</label>
              <v-select
                :reduce="label => label"
                :options="dataYear || []"
                :placeholder="'Năm'"
                @input="YearFilter"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>

      </div>
    </div>

    <div class="page-container">
      <b-form>
        <b-row>
          <b-col
            xl="4"
            lg="4"
            md="4"
            sm="12"
            xs="12"
          >
            <b-form-group>
              <label for="user">Các biện pháp kỹ thuật an toàn</label>
              <b-input-group>
                <b-form-input
                  v-model="dataInput.technicalSafe"
                  type="number"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col
            xl="4"
            lg="4"
            md="4"
            sm="12"
            xs="12"
          >
            <b-form-group>
              <label for="user">Các biện pháp kỹ thuật vệ sinh</label>
              <b-input-group>
                <b-form-input
                  v-model="dataInput.technical"
                  type="number"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col
            xl="4"
            lg="4"
            md="4"
            sm="12"
            xs="12"
          >
            <b-form-group>
              <label for="user">Trang bị phương tiện bảo hộ cá nhân</label>
              <b-input-group>
                <b-form-input
                  v-model="dataInput.personalProtectiveEquipment"
                  type="number"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            xl="4"
            lg="4"
            md="4"
            sm="12"
            xs="12"
          >
            <b-form-group>
              <label for="user">Chăm sóc sức khỏe người lao động</label>
              <b-input-group>
                <b-form-input
                  v-model="dataInput.healthCare"
                  type="number"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col
            xl="4"
            lg="4"
            md="4"
            sm="12"
            xs="12"
          >
            <b-form-group>
              <label for="user">Tuyên truyền, huấn luyện</label>
              <b-input-group>
                <b-form-input
                  v-model="dataInput.training"
                  type="number"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col
            xl="4"
            lg="4"
            md="4"
            sm="12"
            xs="12"
          >
            <b-form-group>
              <label for="user">Đánh giá nguy cơ rủi ro về an toàn, vệ sinh lao động</label>
              <b-input-group>
                <b-form-input
                  v-model="dataInput.risk"
                  type="number"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col
            xl="4"
            lg="4"
            md="4"
            sm="12"
            xs="12"
          >
            <b-form-group>
              <label for="user">Chi phí khác</label>
              <b-input-group>
                <b-form-input
                  v-model="dataInput.another"
                  type="number"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <div class="d-flex mt-1">
        <b-button
          class="mr-1"
          variant="primary"
          @click="save"
        >
          Lưu lại
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BBadge, BDropdown, BButton, BInputGroup, BForm,
  BDropdownItem,
} from 'bootstrap-vue'

import VSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import DataFilter from '../constants/DataFilter'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BBadge,
    BDropdown,
    BDropdownItem,
    BButton,
    BInputGroup,
    VSelect,
  },
  data() {
    return {
      dataYear: [],
      dataInput: {
        technicalSafe: 0,
        technical: 0,
        personalProtectiveEquipment: 0,
        healthCare: 0,
        training: 0,
        risk: 0,
        another: 0,
      },
      yearPercent: 0,
      dataGetCost: {},
    }
  },
  computed: {
    ...mapGetters('fiterYear', ['year', 'yearList']),
  },
  watch: {
    dataGetCost(val) {
      if (val) {
        this.dataInput = {
          technicalSafe: this.dataGetCost.technicalSafe,
          technical: this.dataGetCost.technical,
          personalProtectiveEquipment: this.dataGetCost.personalProtectiveEquipment,
          healthCare: this.dataGetCost.healthCare,
          training: this.dataGetCost.training,
          risk: this.dataGetCost.risk,
          another: this.dataGetCost.another,
        }
      }
    },
  },
  created() {
    this.getYear()
    this.dataYear = this.yearList
    this.yearPercent = this.year
    this.fetchDataCost(this.yearPercent)
  },
  methods: {
    ...mapActions('fiterYear', ['getYear']),
    async fetchDataCost(year) {
      const data = await axiosApiInstance.get(ConstantsApi.GET_COST, {
        params: { year },
      })
      this.dataGetCost = data?.data
      if (data.status === 204) {
        this.dataInput = {
          technicalSafe: 0,
          technical: 0,
          personalProtectiveEquipment: 0,
          healthCare: 0,
          training: 0,
          risk: 0,
          another: 0,
        }
      } else {
        this.dataInput = {
          technicalSafe: this.dataGetCost.technicalSafe,
          technical: this.dataGetCost.technical,
          personalProtectiveEquipment: this.dataGetCost.personalProtectiveEquipment,
          healthCare: this.dataGetCost.healthCare,
          training: this.dataGetCost.training,
          risk: this.dataGetCost.risk,
          another: this.dataGetCost.another,
        }
      }
    },
    YearFilter(val) {
      if (val) {
        this.yearPercent = val
        this.fetchDataCost(this.yearPercent)
      } else {
        this.yearPercent = this.year
        this.fetchDataCost(this.yearPercent)
      }
    },
    save() {
      if (this.yearPercent && this.yearPercent !== 0) {
        const payload = {
          ...this.dataInput,
          healthCare: parseInt(this.dataInput.healthCare, 10),
          personalProtectiveEquipment: parseInt(this.dataInput.personalProtectiveEquipment, 10),
          risk: parseInt(this.dataInput.risk, 10),
          technical: parseInt(this.dataInput.technical, 10),
          technicalSafe: parseInt(this.dataInput.technicalSafe, 10),
          training: parseInt(this.dataInput.training, 10),
          another: parseInt(this.dataInput.another, 10),
          year: this.yearPercent,
        }
        this.$showAllPageLoading()
        axiosApiInstance.post(ConstantsApi.CREATE_COST, payload).then(response => {
          if (response.status === 200) {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast('Thêm thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
          }
        }).catch(e => {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast(e.response.data?.errors[0], {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
      } else {
        this.$root.$bvToast.toast('Phải chọn năm trước khi thêm', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },
  },
}
</script>

<style lang="scss">
#costId{
  .input-group-text {
    background: rgba(186, 191, 199, 0.12);
  }
}
</style>
